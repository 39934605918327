<template>
  <div class="min-h-screen flex flex-col">
    <!-- Navbar -->
    <header class="bg-green-500 text-white py-4 shadow-md">
      <div class="container mx-auto px-4 flex justify-between items-center">
        <h1 class="text-3xl font-extrabold tracking-wide" style="font-family: 'Poppins', sans-serif; text-transform: uppercase; letter-spacing: 0.15em;">
          Turf<span class="text-gray-200"> Planner</span>
        </h1>
        <nav>
          <ul class="flex space-x-6">
            <li>
              <router-link
                to="/"
                class="hover:text-gray-200 transition duration-300"
              >
                Home
              </router-link>
            </li>
            <li>
              <router-link
                to="/features"
                class="hover:text-gray-200 transition duration-300"
              >
                Features
              </router-link>
            </li>
            <li>
              <router-link
                to="/pricing"
                class="hover:text-gray-200 transition duration-300"
              >
                Pricing
              </router-link>
            </li>
            <li>
              <router-link
                to="/contact"
                class="hover:text-gray-200 transition duration-300"
              >
                Contact
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </header>

    <!-- Hero Section -->
    <div
      class="flex flex-col justify-center items-start text-left flex-grow bg-cover bg-center relative h-[570px] px-12"
      style="background-image: url('https://www.turfplanner.com/bgimage.png');"
    >
      <div class="relative z-10 text-white max-w-3xl ml-32 mb-56">
        <h1 class="text-5xl font-extrabold mb-6 leading-tight">Plan Your Perfect Lawn</h1>
        <p class="text-xl mb-8">Discover how TurfPlanner can help you create <br />and maintain the perfect yard with ease.</p>
        <router-link
          to="/signup"
          class="inline-block bg-green-500 hover:bg-green-700 text-white font-bold py-5 px-12 rounded-full text-2xl transition duration-300"
        >
          Get Started
        </router-link>
      </div>
    </div>

    <!-- Features Section -->
    <div class="bg-gray-100 py-20">
      <div class="container mx-auto px-4">
        <h3 class="text-3xl font-bold text-center mb-10 text-gray-800">Features</h3>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div class="bg-white p-8 rounded-lg shadow-md text-center">
            <img
              src="https://via.placeholder.com/50"
              alt="Weed Identification"
              class="mx-auto mb-4"
            />
            <h4 class="text-xl font-semibold mb-2">Weed Identification</h4>
            <p class="text-gray-600">Easily identify weeds in your lawn and get expert advice for their removal.</p>
          </div>
          <div class="bg-white p-8 rounded-lg shadow-md text-center">
            <img
              src="https://via.placeholder.com/50"
              alt="Watering Schedule"
              class="mx-auto mb-4"
            />
            <h4 class="text-xl font-semibold mb-2">Watering Schedule</h4>
            <p class="text-gray-600">Optimize your lawn care with a personalized watering schedule.</p>
          </div>
          <div class="bg-white p-8 rounded-lg shadow-md text-center">
            <img
              src="https://via.placeholder.com/50"
              alt="Fertilizer Recommendations"
              class="mx-auto mb-4"
            />
            <h4 class="text-xl font-semibold mb-2">Fertilizer Recommendations</h4>
            <p class="text-gray-600">Get customized fertilizer recommendations for a healthy and lush lawn.</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer Section -->
    <footer class="bg-gray-800 text-white text-center py-6">
      <p class="text-sm">
        &copy; 2025 TurfPlanner. All rights reserved. |
        <router-link to="/terms" class="text-green-400 hover:underline">Terms of Service</router-link> |
        <router-link to="/privacy" class="text-green-400 hover:underline">Privacy Policy</router-link>
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "HomeView",
};
</script>

<style scoped>
.container {
  max-width: 1200px;
}

#hero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
