<template>
  <div class="flex min-h-screen overflow-auto">
    <!-- Left side - Carousel -->
    <div class="hidden md:block w-[60%] relative">
      <div class="relative h-full">
        <div class="absolute inset-0 bg-teal-700 bg-opacity-30"></div>
        <div class="w-full h-full">
          <img
            :src="currentImage"
            alt="Carousel Image"
            class="object-cover w-full h-full"
          />
        </div>
        <div
          class="flex w-full justify-center flex-col items-center absolute bottom-10 gap-10"
        >
          <div class="text-white w-[500px]">
            <p class="text-lg italic mb-4">
              "Lorem ipsum dolor sit amet consectetur. Quis et pellentesque leo
              morbi. Malesuada eget sit imperdiet eget tempus ut."
            </p>
            <div class="flex items-center">
              <img
                src="https://randomuser.me/api/portraits/men/32.jpg"
                alt="Profile"
                class="w-10 h-10 rounded-full"
              />
              <div class="ml-3">
                <p class="text-sm font-semibold">Martin Guptill</p>
                <p class="text-xs text-gray-300">Los Angeles</p>
              </div>
            </div>
          </div>
          <div class="flex space-x-2">
            <span
              v-for="(image, index) in images"
              :key="index"
              class="w-3 h-3 rounded-full"
              :class="{
                'bg-white': index === currentImageIndex,
                'bg-gray-500': index !== currentImageIndex,
              }"
            ></span>
          </div>
        </div>
      </div>
    </div>

    <!-- Right side - Login Form -->
    <div class="flex items-center justify-center w-full md:w-[40%] bg-white">
      <div class="w-full max-w-md p-8">
        <h2 class="text-2xl font-bold text-gray-800 mb-6">
          Register to Turf Planner
        </h2>

        <form @submit.prevent="submitForm" class="space-y-4">
          <!-- First Name -->
          <div>
            <label class="block text-gray-700 font-semibold">First Name</label>
            <input
              type="text"
              id="firstName"
              v-model="firstName"
              @input="validateName('firstName')"
              required
              class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-teal-500"
            />
          </div>

          <!-- Last Name -->
          <div>
            <label class="block text-gray-700 font-semibold">Last Name</label>
            <input
              type="text"
              id="lastName"
              v-model="lastName"
              @input="validateName('lastName')"
              required
              class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-teal-500"
            />
          </div>
          <!-- Email -->
          <div>
            <label for="email" class="block text-gray-700 font-semibold"
              >Email</label
            >
            <input
              type="email"
              id="email"
              v-model="email"
              required
              class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-teal-500"
            />
          </div>
          <!-- Password -->
          <div>
            <label for="password">Password</label>
            <input
              type="password"
              id="password"
              v-model="password"
              required
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-teal-500"
            />
            <p class="text-xs mt-1 text-gray-600">
              Password must contain at least 8 characters, including uppercase,
              lowercase, and numbers.
            </p>
          </div>
          <!-- Confirm Password -->
          <div>
            <label
              for="confirmPassword"
              class="block text-gray-700 font-semibold"
              >Confirm Password</label
            >
            <input
              type="password"
              id="confirmPassword"
              v-model="confirmPassword"
              required
              :class="{
                'border-red-500':
                  password !== confirmPassword && confirmPassword,
              }"
              class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-teal-500"
            />
            <p
              v-if="password !== confirmPassword && confirmPassword"
              class="text-xs mt-1 text-red-500"
            >
              Passwords do not match!
            </p>
          </div>
          <!-- Zip Code -->
          <div>
            <label for="zipCode" class="block text-gray-700 font-semibold"
              >Zip Code</label
            >
            <input
              type="text"
              id="zipCode"
              v-model="zipCode"
              required
              pattern="\d*"
              @input="validateZip"
              minlength="5"
              maxlength="5"
              class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-teal-500"
            />
          </div>
          <button
            type="submit"
            class="w-full bg-teal-500 text-white p-3 rounded"
          >
            Register
          </button>
          <div class="flex items-center justify-center mb-4">
            <button
              @click="loginWithGoogle"
              class="flex items-center justify-center bg-primary-100 text-primary-600 w-full p-3 rounded"
            >
              <img
                src="https://img.icons8.com/color/48/000000/google-logo.png"
                class="w-6 h-6 mr-2"
              />
              Continue with Google
            </button>
          </div>
          <div class="text-center">
            <router-link to="/login" class="text-sm text-secondary-600"
              >Already have an account?
              <span class="text-primary-600 font-semibold"
                >Sign in</span
              ></router-link
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from "@/api/axoisInstance";

export default {
  name: "SignupView",
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      zipCode: "",
      currentImageIndex: 0,
      images: [
        "https://images.unsplash.com/photo-1719937050814-72892488f741?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://plus.unsplash.com/premium_photo-1710385174244-78d7f68ae13d?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1719937050814-72892488f741?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentImageIndex];
    },
  },
  methods: {
    loginWithGoogle() {
      // Redirect to your backend authentication endpoint
      window.location.href = "https://api.turfplanner.com/auth/google";
    },
    async register() {
      post("/register", {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        password: this.password,
        password_confirmation: this.confirmPassword,
        zip_code: this.zipCode,
      })
        .then((response) => {
          console.log("Registration successful:", response.data);
          this.$router.push("/login");
        })
        .catch((error) => {
          console.error("Registration failed:", error.response.data);
        });
    },
    submitForm() {
      if (this.password !== this.confirmPassword) {
        alert("Passwords do not match!");
        return;
      }
      this.register();
    },
    validateName(field) {
      this[field] = this[field].replace(/[^a-zA-Z]/g, "");
    },
    validateZip() {
      this.zipCode = this.zipCode.replace(/[^0-9]/g, "");
    },
  },
  mounted() {
    setInterval(() => {
      this.currentImageIndex =
        (this.currentImageIndex + 1) % this.images.length;
    }, 3000);
  },
};
</script>
